const currentBox = document.querySelector('.current-box');
const langMenu = document.querySelector('.lang-menu');

currentBox.addEventListener('click', function (event) {
    langMenu.classList.toggle('open');
    currentBox.classList.toggle('open');
    event.stopPropagation(); // Зупиняє подальше поширення події, щоб не викликати document.click одразу після цього
});

const currentBoxMb = document.querySelector('.current-box.mobile');
const langMenuMb = document.querySelector('.lang-menu.mobile');

currentBoxMb.addEventListener('click', function (event) {
    langMenuMb.classList.toggle('open');
    currentBoxMb.classList.toggle('open');
    event.stopPropagation(); // Зупиняє подальше поширення події
});

document.addEventListener('click', function () {
    langMenu.classList.remove('open');
    currentBox.classList.remove('open');

    langMenuMb.classList.remove('open');
    currentBoxMb.classList.remove('open');
});
