import { Turbo } from "@hotwired/turbo-rails";
Turbo.session.drive = false;

import './controllers';

require('./packs/animation')
require('./packs/slider')
require('./packs/theme')
require('./packs/lang')
require('./packs/header')
require('./packs/telegram-bot')
require('./packs/franchise')
require('./packs/popup')
require('./packs/auto_open_popup')
require('./packs/sliding')

require('fslightbox');

